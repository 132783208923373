<template lang="pug">
btn(v-bind='$attrs' ref='btn' :icon='icon' faded :active='pinned' @click='onClick' @mouseover='mouseEnter' @mouseleave='mouseLeave' @focus='mouseEnter' @blur='() => (mouseLeave(), (pinned = false))')
  slot(name='btn')
popover(
  :modelValue='open'
  teleport
  :target='buttonElement'
  :allowedPlacements='["top", "bottom"]'
  :position='position'
  :size='popoverSize'
  :closeable='false'
  :type='popoverType'
  :scrollIntoView='false'
  @mouseover='mouseEnter'
  @mouseleave='mouseLeave'
)
  template(#content)
    slot
      p(v-html='text')
</template>

<script lang="ts">
import Btn from './Btn.vue'
import { emitter } from '@/common'
import Popover, { type PopoverType, type PopoverSize } from '@/components/Popover.vue'
import { type Placement } from '@floating-ui/dom'
import { onKeyStroke, onClickOutside, useDebounceFn } from '@vueuse/core'
import { type PropType, computed, defineComponent, onMounted, ref } from 'vue'

const TooltipButton = defineComponent({
  components: { Popover },
  inheritAttrs: false,
  props: {
    position: { type: [String, Boolean] as PropType<Placement | boolean | undefined>, default: undefined },
    text: String,
    popoverSize: String as PropType<PopoverSize>,
    popoverType: { type: String as PropType<PopoverType>, default: 'dark' },
    debounce: { type: Number, default: 400 },
    icon: { type: String, default: 'help-circle' },
  },
  setup(props) {
    const id: number = Math.random()
    const pinned = ref(false)
    const hoverOrFocused = ref(false)
    const isMouseOver = ref(false)
    const container = ref(null)
    const btn = ref<InstanceType<typeof Btn> | null>(null)
    const debouncedMouseLeave = useDebounceFn(
      () => !isMouseOver.value && (hoverOrFocused.value = false),
      props.debounce,
    )

    emitter.on('openTooltip', (i: any) => id !== i && ((pinned.value = false), (hoverOrFocused.value = false)))
    onKeyStroke('Escape', () => (pinned.value = false))
    onMounted(() => onClickOutside(container.value, () => (hoverOrFocused.value = false)))

    return {
      mouseLeave: () => ((isMouseOver.value = false), debouncedMouseLeave()),
      mouseEnter: () => ((isMouseOver.value = true), (hoverOrFocused.value = true)),
      pinned,
      hoverOrFocused,
      container,
      buttonElement: computed(() => btn.value?.$el),
      btn,
      open: computed(() => pinned.value || hoverOrFocused.value),
      onClick: () => (pinned.value = !pinned.value),
    }
  },
})
export default TooltipButton
</script>
